import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const CareersArea = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div
        className="faq-area  bg-cover pd-top-60 "
        // style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="container">
          <div className="col-lg-12">
            <div className="blog-details-page-content">
              <div className="row">
                <div className="section-title text-center ">
                  <h6 className="sub-title">Be a Presolver! </h6>
                  <h2 className="title">
                    <span>Join</span> Our <span>Team</span>
                  </h2>
                </div>
                <p style={{ textAlign: "center" }}>
                  Do you have a passion for improving legal access? Does the
                  thought of exploring new frontiers through India’s growing
                  legaltech revolution excite you? Join our team and become a
                  Presolver, and together we can make digital justice accessible
                  for all.
                </p>
                <h4 style={{ textAlign: "center", margin: "1rem 0rem" }}>
                  Who We Are
                </h4>
                <div className="thumb">
                  <img src="assets/img/about/who-we-are.png" alt="img" />
                </div>
                <div className="col-lg-6  pd-top-60 ">
                  <h4 className="careees-h4">
                    <FaCheckCircle className="careees-check flip-image" />
                    Our Commitment
                  </h4>
                  <p>
                    Presolv360 is proud to be an equal-opportunity employer. We
                    celebrate different experiences and we're committed to
                    diversity, equity, and inclusion at all levels of the
                    company.
                  </p>
                  <h4 className="careees-h4">
                    <FaCheckCircle className="careees-check flip-image" />
                    Our Values
                  </h4>
                  <p>
                    Our team embodies our eight core values, and if these values
                    speak to you – we’d love to have a word.
                  </p>
                  <h4>
                    <FaCheckCircle className="careees-check flip-image" />
                    Mission Driven
                  </h4>
                  <p>
                    We will passionately apply ourselves to serving our
                    stakeholders, knowing that if we create value for them,
                    value will flow to our company.
                  </p>
                  <h4 className="careees-h4">
                    <FaCheckCircle className="careees-check flip-image" />
                    Extreme Ownership
                  </h4>
                  <p>
                    We think and act like owners. Our focus is on building
                    long-term value, not scoring short-term marks.
                  </p>
                  <h4 className="careees-h4">
                    <FaCheckCircle className="careees-check flip-image" />
                    Boldness
                  </h4>
                  <p>
                    We want to revolutionize. Make a difference. We would rather
                    fail at something meaningful than succeed at something
                    trivial.
                  </p>
                  <h4 className="careees-h4">
                    <FaCheckCircle className="careees-check flip-image" />
                    Creating our own luck
                  </h4>
                  <p>The best way to make progress is to act.</p>
                </div>
                <div className="col-lg-6 pd-top-60 ">
                  <h4 className="careees-h4">
                    <FaCheckCircle className="careees-check flip-image" />
                    Economical
                  </h4>
                  <p>
                    Efficiency is important, and we consider both the costs and
                    benefits of our actions.
                  </p>

                  <h4 className="careees-h4">
                    <FaCheckCircle className="careees-check flip-image" />
                    Team-oriented
                  </h4>
                  <p>
                    In working together, we maintain a low ego, make everyone
                    feel welcome, assume good intent, trust one another, and
                    seek out different perspectives. We empower our teammates to
                    be at their best.
                  </p>

                  <h4 className="careees-h4">
                    <FaCheckCircle className="careees-check flip-image" />
                    Challenged, but not overwhelmed
                  </h4>
                  <p>
                    We are curious people and learning is our second name.
                    Growth happens outside our comfort zone. We achieve our
                    potential through consistent, manageable growth.
                  </p>

                  <h4 className="careees-h4">
                    <FaCheckCircle className="careees-check flip-image" />
                    Integrity
                  </h4>
                  <p>
                    We do the right thing. When we make mistakes, we own and
                    correct them. Everyone can count on us to act according to
                    our values, beliefs, and principles we state we hold. This
                    trust must never be broken.
                  </p>
                  <h4 className="careees-h4">
                    <FaCheckCircle className="careees-check flip-image" />
                    Our Culture
                  </h4>
                  <Link
                    className="links"
                    to="https://edgecraft360-my.sharepoint.com/personal/bhaven_edgecraft360_onmicrosoft_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fbhaven%5Fedgecraft360%5Fonmicrosoft%5Fcom%2FDocuments%2FEdgecraft%20Solutions%20Pvt%20Ltd%2FPresolv360%202%2E0%2FStrategy%20%26%20Innovation%2F3%2E%20BD%20PR%2F8%2E%20Website%2FPresolv360%20Redesign%20Content%2FPresolv360%20Culture%20Code%5Fupdated%5FJune%2023%2Epdf&parent=%2Fpersonal%2Fbhaven%5Fedgecraft360%5Fonmicrosoft%5Fcom%2FDocuments%2FEdgecraft%20Solutions%20Pvt%20Ltd%2FPresolv360%202%2E0%2FStrategy%20%26%20Innovation%2F3%2E%20BD%20PR%2F8%2E%20Website%2FPresolv360%20Redesign%20Content&ga=1"
                  >
                    PDF
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default CareersArea;
