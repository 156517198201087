import React, { useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight, FaStar } from 'react-icons/fa';
import Slider from 'react-slick';

const TestimonialOne = () => {
  const [maxHeight, setMaxHeight] = useState(0);
  const setEqualHeight = () => {
    const divs = document.querySelectorAll(".single-testimonial-inner");
    const maxHeight = Array.from(divs).reduce(
      (max, div) => Math.max(max, div.clientHeight),
      0
    );
    setMaxHeight(maxHeight);
  };
  useEffect(() => {
    setTimeout(setEqualHeight, 1000);
  }, []);
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return <FaAngleRight className={className} onClick={onClick} />;
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return <FaAngleLeft className={className} onClick={onClick} />;
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {/* =================== Testimonial One Start ===================*/}
      <div
        className="testimonial-area pd-top-60 pd-bottom-60"
        // style={{ backgroundImage: 'url("./assets/img/bg/11.png")' }}
      >
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Testimonial</h6>
            <h2 className="title">
              Hear From <span>Your Peers</span>
            </h2>
          </div>
          <div className="testimonial-slider-1  slider-control-round slider-control-dots slider-control-right-top">
            <Slider {...settings}>
              <div className="item">
                <div
                  className="single-testimonial-inner style-1 text-center"
                  style={{ minHeight: maxHeight }}
                >
                  <h5>Mr Sarath Mohan</h5>
                  <p className="designation mb-3">
                    HDFC Bank, Head Legal (Retail)
                  </p>
                  <div className="icon mb-2">
                    <img src="assets/img/icon/25.png" alt="img" />
                  </div>
                  <p>
                    The results of online arbitration were very encouraging – it
                    helped us understand the platform better, we got insights on
                    how we should go about doing this (integrating ODR), and
                    even the response from customers, as compared to the
                    traditional arbitration that we typically do, was much
                    better. When it comes to online mediation, we have seen very
                    good results – the recoveries, and responses, from customers
                    are very encouraging.
                  </p>
                  <div className="ratting-inner mt-4">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                  <div className="thumb">
                    <img
                      src="assets/img/testimonial/Mr Sarath Mohan.jpg"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="item">
                <div
                  className="single-testimonial-inner style-1 text-center"
                  style={{ minHeight: maxHeight }}
                >
                  <h5>Justice Pradeep Nandrajog </h5>
                  <p className="designation mb-3">
                    Former Chief Justice of Rajasthan High Court
                  </p>
                  <div className="icon mb-2">
                    <img src="assets/img/icon/25.png" alt="img" />
                  </div>
                  <p>
                    I also handle arbitrations, all done online in the initial
                    procedural stage. VCA filing is done through digital copies
                    with electronic signatures. There are three types of orders,
                    and the final award is digitally signed and electronically
                    transmitted. There's no legal requirement for physical
                    delivery of the award.
                  </p>
                  <div className="ratting-inner mt-4">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                  <div className="thumb">
                    <img
                      src="assets/img/testimonial/Justice Pradeep Nandrajog.jpg"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="item">
                <div
                  className="single-testimonial-inner style-1 text-center"
                  style={{ minHeight: maxHeight }}
                >
                  <h5>Mr. Sagar Kulkarni</h5>
                  <p className="designation mb-3">Advocate</p>
                  <div className="icon mb-2">
                    <img src="assets/img/icon/25.png" alt="img" />
                  </div>
                  <p>
                    Our case was referred to Presolv360 by the Bombay High Court
                    Mediation Centre. My client was Taiwan based and the other
                    party was Indian. Presolv360 helped in bridging the gap by
                    appointing a mediator and hosting the mediation online. The
                    administration and case manager was very efficient & quick.
                    The online case filing process with Presolv360 is very easy
                    and hassle free, making the entire experience of resolving
                    disputes very pleasant.
                  </p>
                  <div className="ratting-inner mt-4">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                  <div className="thumb">
                    <img
                      src="assets/img/testimonial/Kulkarni_S.png"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      {/* =================== Testimonial One End ===================*/}
    </>
  );
};

export default TestimonialOne;
