let serviceList = [
  {
    title: "Operations Manager",
  },
  {
    title: "HR Manager",
  },
  {
    title: "Sr. Executive (Operations)",
  },
  {
    title: "Key Accounts Manager",
  },
  {
    title: "Executive (Operations)",
  },
  {
    title: "BD Representative",
  },
  {
    title: "System Administrator",
  },
  {
    title: "Executive (Communications)",
  },
  {
    title: "MIS Executive",
  },
  {
    title: "PHP Developer",
  },
  {
    title: "Verification Executive",
  },
  {
    title: "React.JS Developer",
  },
];

export default serviceList;
