import React, { useEffect, useState } from "react";

const ServiceAreaTwo = () => {
  const [maxHeight, setMaxHeight] = useState(0);
  const setEqualHeight = () => {
    const divs = document.querySelectorAll(".details");
    const maxHeight = Array.from(divs).reduce(
      (max, div) => Math.max(max, div.clientHeight),
      0
    );
    setMaxHeight(maxHeight);
  };
  useEffect(() => {
    setTimeout(setEqualHeight, 1000);
  }, []);
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className="service-area  bg-relative pd-top-60 pd-bottom-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h6 class="sub-title">Our Offerings</h6>
                <h2 className="title">
                  Our Dispute Resolution <span>Expertise</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/Arbitration.webp" alt="img" />
                </div>
                <div className="details" style={{ minHeight: maxHeight }}>
                  <div className="icon mb-3">
                    <img
                      src="assets/img/service/arbitration-icon.webp"
                      alt="img"
                    />
                  </div>
                  <h5>Arbitration 360</h5>
                  <p>
                    Institutional online arbitration for enforceable outcomes
                    with the help of smart case management, task automation,
                    blockchain integration, digital signing & stamping, and many
                    other powerful features.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/service/Mediation.webp" alt="img" />
                </div>
                <div
                  className="details"
                  style={{ minHeight: maxHeight, justifyContent: "flex-start" }}
                >
                  <div className="icon mb-3">
                    <img
                      src="assets/img/service/mediation-icon.webp"
                      alt="img"
                    />
                  </div>
                  <h5>Mediation 360</h5>
                  <p>
                    Platform to facilitate amicable resolutions by leveraging
                    digital infrastructure, remote communication channels,
                    e-resolution camps, settlement builders, and actionable
                    reports and insights.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaTwo;
