import React from "react";

const ContactAreaThree = () => {
  return (
    <>
      {/* ========================= contact Area Three start =========================*/}
      <div className="price-process-area  pd-top-120 ">
        <div className="contact-inner-1 contact-inner-2">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <img className="w-100" src="assets/img/about/12.png" alt="img" />
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 ">
                <h6 className="sub-title">Features</h6>
                <h2 className="title mb-4">
                  Choose <span>Presolv360 </span>as your{" "}
                  <span>Dispute Resolution</span> Expert!
                </h2>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img
                      src="assets/img/icon/click.svg"
                      class="flip-image"
                      alt="img"
                    />
                  </div>
                  <div className="media-body">
                    <h5>Easy Resolution</h5>
                    <p className="mb-0">
                      Simple and user-centric dispute resolution
                    </p>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img
                      src="assets/img/icon/click.svg"
                      class="flip-image"
                      alt="img"
                    />
                  </div>
                  <div className="media-body">
                    <h5>Seamless Support</h5>
                    <p className="mb-0">
                      Fully integrated support mechanisms for a smooth user
                      experience
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left me-3">
                    <img
                      src="assets/img/icon/click.svg"
                      class="flip-image"
                      alt="img"
                    />
                  </div>
                  <div className="media-body">
                    <h5>Certified and Compliant</h5>
                    <p className="mb-0">ISO-certified and GDPR-compliant</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*========================= contact-inner Three end =========================*/}
    </>
  );
};

export default ContactAreaThree;
