import React from "react";

import BrandAreaOne from "../components/BrandAreaOne";
import Breadcrumb from "../components/Breadcrumb";
import ContactAreaThree from "../components/ContactAreaThree";
import CounterAreaFour from "../components/CounterAreaFour";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import TestimonialOne from "../components/TestimonialOne";

const WhyUs = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Why Us?"} />

      {/* About Area One */}
      <ContactAreaThree />
      <CounterAreaFour />

      <TestimonialOne />
      <BrandAreaOne />
      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default WhyUs;
