import React, { useRef } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

const ContactMain = () => {
  const form = useRef();
  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    try {
      const response = await fetch(
        "https://presolv360.com/wp-json/contact-apis/v1/send-email",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData), // Assuming formData is a JSON object
        }
      );

      if (response.ok) {
        // Email sent successfully
        toast.success("Email sent successfully!");
        // You can reset the form here if needed
        form.current.reset();
      } else {
        // Email sending failed
        toast.error("Failed to send email. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("An error occurred while sending the email.");
    }
  };
  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position="bottom-center" reverseOrder={false} />
      {/* <div className="contact-area pd-top-60 pd-bottom-60">
        <div className="container">
          <div className="contact-page-inner bg-gray">
            <div className="section-title mb-4 pb-2">
              <h2 className="title">Direct contact us? </h2>
              <p className="content mb-0">
                For your car we will do everything advice, repairs and
                maintenance. We are the some preferred choice by many car owners
                because our experience and knowledge is selfe vident.For your
                car we will do som everything.
              </p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="name"
                      name="user_name"
                      type="text"
                      placeholder="Enter Your Name."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="email"
                      name="user_email"
                      type="email"
                      placeholder="Enter Your Email."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="subject"
                      name="subject"
                      type="text"
                      placeholder="Enter Your Subject."
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="single-input-inner">
                    <textarea
                      name="message"
                      id="massage"
                      cols="1"
                      rows="5"
                      placeholder="Enter Your Massage ..."
                      required
                    />
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn btn-base border-radius-5"
                    type="submit"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}

      {/* contact list */}
      <div className="contact-page-list  pd-top-60 pd-bottom-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/13.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Contacts us</h5>
                  <h6>+91 8447728708</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/14.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Email</h5>
                  <h6>
                    <Link className="links" to="mailto:info@presolv360.com">
                      info@presolv360.com
                    </Link>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/15.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Location</h5>
                  <h6>Mumbai, India</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Map  */}
      <div className="contact-g-map">
        <iframe
          title="address"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3774.1838442892767!2d72.82890637495147!3d18.923254956750437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cefd2f354ec1%3A0x954573b0f96b04b9!2sPresolv360!5e0!3m2!1sen!2sin!4v1696415051689!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>

      {/* ================= Contact Main End =================*/}
    </>
  );
};

export default ContactMain;
