import React from "react";

import BrandAreaOne from "../components/BrandAreaOne";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import StandardsContent from "../components/StandardsContent";

const Standards = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Standards"} />

      <StandardsContent />
      <BrandAreaOne />
      <FooterOne />
    </>
  );
};

export default Standards;
