import React from "react";

const AboutAreaFour = () => {
  return (
    <>
      {/* =============== About Area Three End ===============*/}
      <div className="about-area pd-top-60 pd-bottom-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner pt-4"
                style={{ padding: "0 3rem 0 3rem" }}
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <img
                  className="animate-main-img"
                  src="assets/img/about/14.png"
                  alt="img"
                />
                <img
                  className="main-img "
                  style={{ margin: "1rem 1rem 0 1rem" }}
                  src="assets/img/founders/Founding Team_old.jpg"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 align-self-center "
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1500"
            >
              <div
                className="section-title mt-5 mt-lg-0 text-align-center-767"
                style={{ marginBottom: 0 }}
              >
                <h6 class="sub-title">How it all began</h6>
                <h2 className="title">
                  <span>Founder’s</span> Story
                </h2>
                <p
                  className="content mb-4 mb-xl-5"
                  style={{ textAlign: "left" }}
                >
                  Heaps of papers, complex procedures, endless causelists, and
                  the echoes of ‘tareekh pe tareekh’ (adjournments) – these are
                  commonplace sights and sounds of Indian courtrooms. Aman,
                  Bhaven, and Namita witnessed these in multiple courtrooms
                  again and again. It was a two-edged sword – while they had to
                  live through the burdens of these inefficiencies, they also
                  inspired them to create change and led to the birth of
                  Presolv360.<br></br>
                  <br></br>The tipping point was an incident in one such
                  courtroom, where the judge told Bhaven, “Son, your father
                  filed this case, and it is your child that will continue to
                  fight it.” In that moment, despair turned into determination,
                  and the three friends set out on a journey to change the
                  narrative of dispute resolution.<br></br>
                  <br></br>Presolv360 thus started with the vision of becoming a
                  global leader in resolving disputes using technology. The
                  heart of the founders’ vision for the company is using a blend
                  of the latest technologies and human ingenuity to craft
                  solutions that revolutionize the global justice landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Three End ===============*/}
    </>
  );
};

export default AboutAreaFour;
