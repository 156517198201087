import React from "react";

const StandardsContent = () => {
  return (
    <div class="container">
      {" "}
      <h4 className="multi-title">
        Online Dispute Resolution Standards adopted by Presolv360
      </h4>
      <p className="multi-paragraph">
        <strong>Introduction</strong>
      </p>{" "}
      <p className="multi-paragraph">
        While online dispute resolution (“ODR”) standards, principles and notes
        are of a non-binding nature and relate to online dispute resolution
        systems in general, they provide boilerplate parameters while using
        information technology for resolving disputes online.
      </p>{" "}
      <p className="multi-paragraph">
        For the purpose of Presolv360’s ODR platform (“Platform”), we have
        referred to:
      </p>{" "}
      <ul>
        {" "}
        <li>
          Technical Notes on Online Dispute Resolution of the United Nation
          Commission on International Trade Law (“UNCITRAL”): UNCITRAL was
          established by the United Nations to promote the progressive
          harmonization and unification of international trade law. These notes
          are a non-binding descriptive document reflecting elements of an
          online dispute resolution process. It recommends that all stakeholders
          should use these notes in designing and implementing online dispute
          resolution systems for resolving disputes.
        </li>{" "}
        <li>
          Ethical Principles for Online Dispute Resolution (Project of
          the&nbsp;National Center for Technology and Dispute Resolution): These
          principles are designed to enhance the quality, effectiveness, and
          scope of dispute resolution processes with technological components.
        </li>{" "}
        <li>
          International Council for Online Dispute Resolution (“ICODR”)
          Standards: The ICODR &nbsp;is a response to the recent, rapid and
          widespread adoption of technology-supported systems for dispute
          resolution that have been taking place in China, India, England,
          Canada, the U.S. and elsewhere, both in the private as well as the
          public sector. These standards provide a touchstone for best
          practices, rules, qualifications, and certification efforts for online
          dispute resolution processes and practices.
        </li>{" "}
        <li>
          Online Dispute Resolution Standards of Practice by the Internet
          Corporation for Assigned Names and Numbers (“ICANN”): These standards
          of practice are recommended by the Advisory Committee of the National
          Centre for Technology and Dispute. The standards are based on the
          current literature and research in the field of ODR and are offered as
          guidelines for practice across the spectrum of ODR.
        </li>{" "}
      </ul>{" "}
      <h4 className="multi-title">Standards</h4>
      <ul>
        {" "}
        <li>
          <strong>Accessibility:</strong> The Platform is a user-friendly
          web-based platform accessible from mobile and desk-based devices
          providing greater outreach regardless of geographical boundaries. It
          has a simple and intuitive user interface and provides a guidebook and
          video tutorials for smooth participation and navigation.
        </li>{" "}
        <li>
          <strong>Accountability:</strong> The Platform through its Dispute
          Resolution Rules (“Rules”) remains accountable to the community it
          serves and functions within the legal framework prevalent in India,
          for the time being in force.
        </li>{" "}
        <li>
          <strong>Affordability:</strong> Resolving disputes on the Platform is
          an economical alternative to litigation and in-person dispute
          resolution. With its unique approach, the Platform facilitates
          zero-cost and expeditious dispute resolution.
        </li>{" "}
        <li>
          <strong>Competence:</strong> The systems and processes are built and
          designed keeping in mind effective dispute resolution. The Platform
          providers and practitioners are competent in fields including but not
          limited to dispute resolution, law, technology, language and culture.
        </li>{" "}
        <li>
          <strong>Confidentiality:</strong> While the Platform technology and
          process implementation have been designed and developed keeping in
          mind confidentiality obligations applicable to dispute resolution, the
          Rules bind the parties, practitioners and providers to
          confidentiality. The Privacy Policy sets out what data will be
          collected and how it will be used.
        </li>{" "}
        <li>
          <strong>Consensual Resolution:</strong> The Platform, Rules and
          processes are designed to encourage parties, wherever appropriate, to
          resolve disputes using consensual processes.
        </li>{" "}
        <li>
          <strong>Empowerment:</strong> The Platform and Rules are centered
          around the idea of empowering people to resolve their own disputes.
          The systems and processes are developed and designed in a manner that
          increases access to justice, enhancement of choices and effective
          decision-making opportunities.
        </li>{" "}
        <li>
          <strong>Equality:</strong> The Platform and processes are designed in
          a manner that ensures equal treatment of parties and follows the
          principles of natural justice and equity while keeping in mind quick,
          economical, effective and efficient dispute resolution.
        </li>{" "}
        <li>
          <strong>Expertise:</strong> While the Platform providers possess
          relevant understanding of technology, design and law, the
          practitioners are subject to a comprehensive selection and empanelment
          process keeping in mind their qualification, competence, knowledge and
          expertise. The practitioners are required to complete all requisite
          trainings and certifications as may be prescribed by Presolv360 and
          are also recommended to periodically engage in various forms of
          continued education to refine and improve their knowledge and skills.
        </li>{" "}
        <li>
          <strong>Fairness, Impartiality and Neutrality:</strong> The Platform
          together with the Rules ensure actual and perceived fairness,
          impartiality and neutrality of the systems, processes and
          practitioners throughout the dispute resolution process.
        </li>{" "}
        <li>
          <strong>Honesty:</strong> Information, data and documents, to the
          extent possible and permitted by law, is gathered, managed and
          presented on an ‘as is’ basis to ensure it is not misrepresented or
          presented out of context. <em>Ex-parte</em> communications, to the
          extent possible, are prohibited, and the practitioners are required to
          make appropriate declarations and disclosures before and during the
          dispute resolution process.
        </li>{" "}
        <li>
          <strong>Informed Participation:</strong> The Rules, systems and
          processes ensure explicit disclosure to, competence of, understanding
          and acceptance by participants about the risks and benefits of
          resolving disputes through the Platform.
        </li>{" "}
        <li>
          <strong>Innovation:</strong> Presolv360 prides itself on constantly
          innovating and improving service delivery and technology while keeping
          in mind the requirements of the community and the law.
        </li>{" "}
        <li>
          <strong>Legal Obligation:</strong> The systems and processes abide by
          and uphold the laws prevalent in India, to the extent applicable.
        </li>{" "}
        <li>
          <strong>Security:</strong> All reasonable efforts are made to keep
          data and communication between parties and all persons involved in the
          dispute resolution process secure and confidential. For more
          information, refer Part III of this document.
        </li>{" "}
        <li>
          <strong>Transparency:</strong> The Platform, Rules and processes
          explicitly set out the risks and benefits of participation, the form
          and enforceability of dispute resolution processes and outcomes; the
          identities, affiliations, obligations and conflicts of interest of the
          parties and practitioners; and the security efforts, confidentiality
          and privacy policy involved.
        </li>{" "}
      </ul>{" "}
      <p className="multi-paragraph">
        <strong>Security</strong>
      </p>{" "}
      <p className="multi-paragraph">
        Presolv360, on the Platform, has implemented appropriate safeguards to
        prevent unauthorized access to any party’s information or documents and
        to maintain data security. Presolv360 employs encryption technology for
        protecting sensitive information. However, electronic mails or other
        electronic means of communication may not be encrypted and it is
        recommended that parties do not communicate any confidential information
        through the aforementioned means. Presolv360 follows generally accepted
        industry standards to protect the information and documents submitted on
        the Presolv360 Platform. However, no method of electronic transmission
        or storage is 100% secure. While Presolv360 strives to use commercially
        acceptable means to protect the information and documents of the
        parties, Presolv360 cannot guarantee its absolute security and assumes
        no liability or responsibility for disclosure of any party’s information
        due to errors in transmission, unauthorized third-party access, or other
        causes beyond Presolv360’s control. The parties play an important role
        in keeping its information secure. Parties should not share its account
        information with any third party. Presolv360 shall not be liable for any
        loss or damage caused by the use of third-party applications by the
        parties.
      </p>
    </div>
  );
};

export default StandardsContent;
