import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-60 pd-bottom-60">
        <div className="container text-align-center-767">
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-lg-5">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                {/* <img
                  className="animate-img-1 top_image_bounce"
                  src="assets/img/about/2.png"
                  alt="img"
                />
                <img
                  className="animate-img-2 left_image_bounce"
                  src="assets/img/about/3.png"
                  alt="img"
                />
                <img
                  className="animate-img-3 top_image_bounce"
                  src="assets/img/banner/5.svg"
                  alt="img"
                /> */}
                <img
                  className="main-img"
                  src="assets/img/about/8777961.jpg"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-7"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-2 mt-2 mt-lg-0">
                <h6 className="sub-title">Who we are</h6>
                <h2 className="title">
                  About <span>Presolv360</span>
                </h2>
              </div>{" "}
              <p className="content ">
                Presolv360 is an independent online dispute resolution (‘ODR’)
                platform that facilitates quick and effective out-of-court
                resolution of disputes, while drastically reducing the costs and
                resources involved in resolving them. It harnesses the power of
                technology to enable the participation of disputing parties from
                remote locations and diverse socio-economic backgrounds, thereby
                creating a level playing field.
              </p>
              <div className="row" style={{ justifyContent: "center" }}>
                <h3 className="title">Features</h3>
                <div className="col-lg-4 col-md-12 single-about-outer">
                  <div className="single-about-inner">
                    <div className="thumb mb-3">
                      <img src="assets/img/icon/Resolution.svg" alt="img" />
                    </div>
                    <div className="details">
                      <h5>Easy Resolution</h5>
                      <p>Simple and user-centric dispute resolution</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 single-about-outer">
                  <div className="single-about-inner">
                    <div className="thumb mb-3">
                      <img src="assets/img/icon/Communication.svg" alt="img" />
                    </div>
                    <div className="details">
                      <h5>Seamless Support</h5>
                      <p>
                        Fully integrated support mechanisms for a smooth user
                        experience
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-md-12 single-about-outer ">
                  <div className="single-about-inner">
                    <div className="thumb mb-3">
                      <img src="assets/img/icon/Verification.svg" alt="img" />
                    </div>
                    <div className="details">
                      <h5>Certified and Compliant</h5>
                      <p>ISO-certified and GDPR-compliant</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
