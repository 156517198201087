import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const AboutAreaThree = () => {
  return (
    <>
      {/* =============== About Area Three End ===============*/}
      <div className="about-area  pd-top-60 pd-bottom-60">
        <div className="container">
          <div class="section-title text-center">
            <h6 class="sub-title">About Us</h6>
            <h2 class="title">
              Resolving Disputes, <span>Evolving Relations</span>
            </h2>
          </div>
          <div className="row" style={{ alignItems: "center" }}>
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner mb-4 mb-lg-0">
                <img
                  className="main-img"
                  style={{ borderRadius: "35px" }}
                  src="assets/img/about/11.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                {/* <h6 className="sub-title">ABOUT US</h6> */}
                <p>
                  Presolv360 is an ODR platform that takes dispute resolution to
                  citizens' fingertips by digitizing the mediation,
                  conciliation, and arbitration journey. Based on the pillars of
                  accessibility, convenience, innovation, and automation, the
                  ODR platform creates a level-playing field by promoting
                  participation from remote locations and diverse socio-economic
                  backgrounds. As a result, parties can achieve timely
                  resolution of their dispute, while drastically reducing the
                  cost involved in resolving them.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> Digital Transformation
                      </li>
                      <li>
                        <FaCheckCircle /> Core Values
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> Significant Impact
                      </li>
                      <li>
                        <FaCheckCircle /> Widespread Reach
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="about-area  pd-top-60 pd-bottom-60">
        <div className="container">
          <div class="section-title text-center" style={{ margin: "0" }}>
            <h6 class="sub-title" style={{ margin: "1rem 0rem" }}>
              Highlights
            </h6>
            <h2 class="title">
              Notable <span>Milestones</span>
            </h2>
          </div>
          <div className="row" style={{ alignItems: "center" }}>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                {/* <h6 className="sub-title">ABOUT US</h6> */}

                <p className="content">
                  We have facilitated resolutions in 70,000+ small-to-mid value
                  financial, consumer, insurance, real estate and MSME disputes
                  over the last five years. Over 4,50,000 disputing parties
                  saved an aggregate of INR 362 million and 7.8 million days by
                  choosing to resolve their dispute online. Additionally,
                  Presolv360 served citizens in 7,257 pin codes in the country
                  and helped increase access and participation in dispute
                  resolution proceedings by 30% compared to in-person
                  mechanisms. Nearly 18% of the disputes registered were
                  amicably settled at the{" "}
                  <span style={{ whiteSpace: "nowrap" }}>pre-proceedings</span>{" "}
                  stage.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner mb-4 mb-lg-0">
                <img
                  className="main-img"
                  style={{ borderRadius: "35px", width: "100%" }}
                  src="assets/img/about/notable milestones.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Three End ===============*/}
    </>
  );
};

export default AboutAreaThree;
