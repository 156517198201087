import React from "react";
import {
  FaArrowRight,
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterOne = () => {
  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <footer className="footer-area bg-black bg-cover">
        <div className="container container-767">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                <Link to="/">
                  <div className="thumb">
                    <img src="assets/img/logo.png" alt="img" />
                  </div>
                </Link>
                <div className="details">
                  <h5 style={{ color: "#fff" }}>Disclaimer</h5>
                  <p>
                    Presolv360 is not a law firm and does not provide legal
                    advice. The use of any materials or services is not a
                    substitute for legal advice. Only a legal practitioner can
                    provide legal advice.
                  </p>

                  <ul className="social-media">
                    <li>
                      <Link
                        to="https://www.facebook.com/presolv360/"
                        target="_blank"
                      >
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://twitter.com/i/flow/login?redirect_after_login=%2Fpresolv360"
                        target="_blank"
                      >
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.linkedin.com/company/presolv360/mycompany/"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.youtube.com/channel/UCIa8tc-3Q1lpW2irMnVGniA/videos"
                        target="_blank"
                      >
                        <FaYoutube />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">About Presolv360</h4>
                <ul>
                  <li>
                    <Link to="/about">
                      <FaArrowRight /> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/what-we-do">
                      <FaArrowRight /> What We Do
                    </Link>
                  </li>
                  <li>
                    <Link to="/how-it-works">
                      <FaArrowRight /> How It Works
                    </Link>
                  </li>
                  <li>
                    <Link to="/why-us">
                      <FaArrowRight /> Why Us?
                    </Link>
                  </li>
                  <li>
                    <Link to="/resources">
                      <FaArrowRight /> Resources
                    </Link>
                  </li>
                  <li>
                    <Link to="/careers">
                      <FaArrowRight />
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <FaArrowRight /> Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Quick Help</h4>
                <ul>
                  <li>
                    <Link to="/faq">
                      <FaArrowRight /> FAQ's
                    </Link>
                  </li>
                  <li>
                    <Link to="/fees">
                      <FaArrowRight />
                      Rules, Code & Fees
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms_conditions">
                      <FaArrowRight /> Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy_policy">
                      {/* <Link to="https://presolv360.com/privacy_policy"> */}
                      <FaArrowRight /> Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/cookie_policy">
                      {/* <Link to="https://presolv360.com/cookie_policy"> */}
                      <FaArrowRight /> Cookie Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/odr_standard">
                      {/* <Link to="https://presolv360.com/odr_standard"> */}
                      <FaArrowRight />
                      Standards
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">
                  <Link to="/contact">Contact Us</Link>
                </h4>
                <div className="widget widget_contact">
                  <ul className="details text-white">
                    <li>
                      <FaMapMarkerAlt />
                      Mumbai, India
                    </li>
                    <li className="mt-3">
                      <Link to="tel:+918447728708">
                        <FaPhoneAlt />
                        +91 8447728708
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link to="mailto:info@presolv360.com">
                        <FaEnvelope /> info@presolv360.com
                      </Link>
                    </li>
                  </ul>
                  <ul className="social-media mt-4">
                    <li>
                      <Link
                        to="https://www.facebook.com/presolv360/"
                        target="_blank"
                      >
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://twitter.com/i/flow/login?redirect_after_login=%2Fpresolv360"
                        target="_blank"
                      >
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.linkedin.com/company/presolv360/mycompany/"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.youtube.com/channel/UCIa8tc-3Q1lpW2irMnVGniA/videos"
                        target="_blank"
                      >
                        <FaYoutube />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container container-767">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© presolv360.com 2023 | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
                <Link to="/terms_conditions">Terms &amp; Condition</Link>

                <Link to="/privacy_policy">Privacy Policy</Link>
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
