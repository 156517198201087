import React from 'react';
import { Link } from 'react-router-dom';

const FeesContent = () => {
  return (
    <>
      <div class="container">
        <h4 className="multi-title">
          Dispute Resolution <span>Rules</span>
        </h4>
        <p className="multi-paragraph">
          {" "}
          If you desire to resolve your dispute using Presolv360’s platform, you
          agree to be bound by its Dispute Resolution Rules (“Rules”).
        </p>
        <p className="multi-paragraph">
          The Rules are designed to help you take maximum advantage of
          Presolv360’s online dispute resolution (“ODR”) system and prevent
          needless litigation, safeguard yourself from adversities of disputes
          and resolve them quickly, economically, effectively and efficiently.
        </p>
        <p className="multi-paragraph">
          Section 1 deals with preliminaryaspects such as definitions, scope,
          modalities pertaining to communication, party obligations in respect
          of platform use, and data. Section 2 covers rules for arbitration
          proceedings on Presolv360’s ODR platform. Section 3 encapsulates rules
          for mediation / conciliation proceedings on Presolv360’s ODR platform.
        </p>
        <p className="multi-paragraph">
          Note: If a dispute is referred for resolution to Presolv360 by a
          court, statutory authority, or governing body, then such disputes
          shall be resolved in accordance with the rules, regulations, and
          guidelines of the referring authority that are in effect as of the
          date on which request is made to Presolv360.
        </p>
        <h4 className="multi-title">
          {" "}
          <span>Arbitrators’ and Mediators’</span> Code of Conduct and
          Disclosure Rules
        </h4>
        <p className="multi-paragraph">
          The Arbitrators’ and Mediators’ Code of Conduct and Disclosure Rules
          (“Code”) set out the general framework for ethics with which the
          arbitrators and mediators / conciliators empanelled with Presolv360
          shall conduct the arbitration and mediation / conciliation
          proceedings, respectively. The Code also governs the disclosure
          requirements to act as an arbitrator or a mediator / conciliator.
        </p>
        <p className="multi-paragraph">
          While Section 1 deals with preliminary aspects such as definitions,
          scope, modalities pertaining to communication, and arbitrators’ and
          mediators’ / conciliators’ obligations in respect of platform use,
          Section 2 and 3 govern the code of conduct and disclosure rules for
          arbitrators and mediators / conciliatorsempanelled with Presolv360,
          respectively.
        </p>
        <p className="multi-paragraph">
          Click here to access the governing documents for resolving disputes on
          Presolv360’s ODR platform.
        </p>
        <h4 className="multi-title">
          <span>Model Fee</span> Schedule
        </h4>
        <p className="multi-paragraph">
          If a dispute is referred for resolution to Presolv360 by a court,
          statutory authority, or governing body, then the fee for resolution of
          such disputes shall be in accordance with the fee schedule prescribed
          by the referring authority.
        </p>
        <p className="multi-paragraph">
          Model fee per dispute for arbitration administered by Presolv360 on
          its ODR platform is as under:
        </p>{" "}
        <table class="table table-bordered  table-hover">
          <thead class="thead-light">
            <tr>
              <th>Claim Amount (INR)</th>
              <th>Consolidated Fee (INR)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Up to 50,000</td>
              <td>5,000</td>
            </tr>
            <tr>
              <td>50,001 to 1,00,000</td>
              <td>10,000</td>
            </tr>
            <tr>
              <td>1,00,001 to 5,00,000</td>
              <td>20,000</td>
            </tr>
            <tr>
              <td>5,00,001 to 25,00,000</td>
              <td>20,000 + 3% of claim amount over 5,00,000</td>
            </tr>
            <tr>
              <td>25,00,001 to 50,00,000</td>
              <td>80,000 + 2% of claim amount over 25,00,000</td>
            </tr>
            <tr>
              <td>50,00,001 to 1,00,00,000</td>
              <td>1,30,000 + 1% of claim amount over 50,00,000</td>
            </tr>
          </tbody>
        </table>
        <p className="multi-paragraph">
          For claim amounts over Rs. 1,00,00,000, kindly reach out to us at{" "}
          <Link className="links" to="mailto:info@presolv360.com">
            info@presolv360.com
          </Link>
          .
        </p>
        <p className="multi-paragraph">
          Model fee per dispute for mediation / conciliation administered by
          Presolv360 on its ODR platform is as under:
        </p>{" "}
        <p className="multi-paragraph">
          Registration fee – Rs. 4,500; Administrative fee per session – Rs.
          5,000; Mediator / Conciliator fee – As agreed between the mediator /
          conciliator and the parties.
        </p>
        <b>Note:</b>
        <p className="multi-paragraph">
          (a) The aforesaid fees are non-refundable and exclusive of applicable
          taxes.
        </p>{" "}
        <p className="multi-paragraph">
          (b) The fees are payable irrespective of the outcome of the dispute,
          promptly upon receipt of a payment requisition by Presolv360.
        </p>{" "}
        <p className="multi-paragraph">
          (c) Stamp duty, statutory charges, out-of-pocket expenses, if any,
          shall be reimbursed to Presolv360 in accordance with the fee imposed
          by law or on actual basis, as the case may be.
        </p>{" "}
        <p className="multi-paragraph">
          (d) The cost or professional charges of any legal practitioner or
          authorised representative, including without limitation, cost of
          appointment and / or representation and / or any other incidental
          costs shall be borne by the respective parties.
        </p>{" "}
        <p className="multi-paragraph">
          (e) Each party shall be responsible for its respective taxation
          obligations as per the applicable laws for the time being in force.
          <h4 className="multi-title">
            Project <span>‘Presolv for All’</span>
          </h4>
        </p>{" "}
        <p className="multi-paragraph">
          Under thisproject, Presolv360 extends its services free of cost to
          persons from low-income groups. Based on the eligibility and facts of
          the dispute, applications will be screened, and eligible parties will
          be notified.
        </p>
        <h4 className="multi-title">
          Project <span>‘Hear the Difference’</span>
        </h4>
        <p className="multi-paragraph">
          With this project, Presolv360 aims to achieve the objective of making
          dispute resolution accessible to persons with disabilities.
          Communications sent via the platform will provide an option to solicit
          an Indian Sign Language interpreter to help people with hearing
          disabilities understand and communicate.
        </p>
        <p className="multi-paragraph">
          If you have any questions or require any clarifications, please feel
          free to reach out to us at{" "}
          <Link className="links" to="mailto:info@presolv360.com">
            info@presolv360.com
          </Link>
        </p>
      </div>
    </>
  );
};

export default FeesContent;
