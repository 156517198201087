import React from "react";

import BrandAreaOne from "../components/BrandAreaOne";
import Breadcrumb from "../components/Breadcrumb";
import CookiePolicyContent from "../components/CookiePolicyContent";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";

const CookiePolicy = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Cookie Policy"} />

      <CookiePolicyContent />
      <BrandAreaOne />
      <FooterOne />
    </>
  );
};

export default CookiePolicy;
