import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

import CyclingDotsTitle from "./CyclingDotsTitle";

const BannerOne = () => {
  const form = useRef();
  const sectionRef = useRef(null);

  const [isVisibleScroll, setIsVisibleScroll] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const threshold = 5;
      setIsVisibleScroll(scrollPosition < threshold);
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    try {
      const response = await fetch(
        "https://presolv360.com/wp-json/contact-apis/v1/send-email",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        toast.success("Email sent successfully!");
        form.current.reset();
      } else {
        toast.error("Failed to send email. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("An error occurred while sending the email.");
    }
  };
  return (
    <>
      {/* ================== BannerOne Start ==================*/}
      <div
        className="banner-area bg-relative banner-area-1 pd-bottom-90 bg-cover"
        style={{ backgroundImage: 'url("./assets/img/banner/0.png")' }}
      >
        <img
          className="animate-img-1 top_image_bounce"
          src="assets/img/banner/2.png"
          alt="img"
        />
        <img
          className="animate-img-2 left_image_bounce"
          src="assets/img/banner/5.svg"
          alt="img"
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="banner-inner pe-xl-5">
                <h6 className="subtitle aos-init aos-animate">
                  Easy. Efficient. Enforceable
                </h6>
                <h2
                  className="title"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  <span>Online Dispute Resolution</span> can transform your
                  business.
                </h2>
                <p
                  className="content pe-xl-5"
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                >
                  Providing Dispute Resolution at your fingertips.
                </p>
                <Link
                  className="btn btn-border-base"
                  data-aos="fade-right"
                  data-aos-delay="300"
                  data-aos-duration="1500"
                  to="/about"
                >
                  Discover More <FaPlus />
                </Link>
              </div>
            </div>
            <div className="col-xl-6 ">
              <div className="contact-area">
                <div className="container">
                  <div className="contact-page-inner ">
                    <div
                      className="section-title mb-4 pb-2"
                      style={{ textAlign: "left" }}
                    >
                      <h2 className="title">
                        Request a <span>call back</span>
                        <CyclingDotsTitle />
                      </h2>
                    </div>
                    <form ref={form} onSubmit={sendEmail}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="single-input-inner">
                            <input
                              id="name"
                              name="user_name"
                              type="text"
                              placeholder="Enter Your Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="single-input-inner">
                            <input
                              id="email"
                              name="user_email"
                              type="email"
                              placeholder="Enter Your Email Id"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="single-input-inner">
                            <input
                              id="mobile"
                              name="mobile"
                              type="text"
                              placeholder="Enter Your Mobile No."
                              required
                            />
                          </div>{" "}
                          <div className="single-input-inner">
                            <input
                              id="Company"
                              name="Company"
                              type="text"
                              placeholder="Enter Your Company"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 ">
                          <button
                            style={{ backgroundColor: "rgb(248 249 252)" }}
                            className="btn btn-border-base"
                            data-aos="fade-right"
                            data-aos-delay="300"
                            data-aos-duration="1500"
                            type="submit"
                          >
                            Submit
                            {/* <FaPlus /> */}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* <div
                className="banner-thumb mt-5 mt-lg-0 ms-4 ms-lg-0"
                data-aos="fade-left"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="banner-animate-img banner-animate-img-1 top_image_bounce"
                  src="assets/img/banner/2.png"
                  alt="img"
                />
                <img
                  className="banner-animate-img banner-animate-img-2"
                  src="assets/img/banner/3.svg"
                  alt="img"
                />
                <img
                  className="banner-animate-img banner-animate-img-3 left_image_bounce"
                  src="assets/img/banner/4.svg"
                  alt="img"
                />
                <div className="banner-logo-container">
                  <div className="rotating-logo-container">
                    <img
                      className="left-chat"
                      src="assets/img/banner/left-chat.webp"
                      alt="Rotating Image"
                    />
                    <img
                      className="right-chat"
                      src="assets/img/banner/right-chat.webp"
                      alt="Rotating Image"
                    />
                  </div>
                </div>
              </div> */}
            </div>
            {isVisibleScroll && (
              <img
                alt=""
                onClick={scrollToSection}
                src="assets/img/icon/Scroll.png"
                className="scroll-wheel"
              ></img>
            )}
          </div>
        </div>
      </div>
      <div ref={sectionRef}></div>
      {/* ================== BannerOne End ==================*/}
    </>
  );
};

export default BannerOne;
