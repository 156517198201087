import React, { useEffect } from "react";

const FaqAreaOne = () => {
  // useEffect(() => {
  //   const accordion = document.getElementById("accordionExample");
  //   const handleAccordionHide = (event) => {
  //     const allCollapsed = !Array.from(
  //       accordion.querySelectorAll(".accordion-item.show")
  //     ).length;

  //     if (allCollapsed) {
  //       document.getElementById("after-all-collapse").style.display = "block";
  //     }
  //   };

  //   accordion.addEventListener("hide.bs.collapse", handleAccordionHide);

  //   return () => {
  //     accordion.removeEventListener("hide.bs.collapse", handleAccordionHide);
  //   };
  // }, []);

  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div
        className="faq-area  bg-cover pd-top-60 pd-bottom-60"
        // style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="container">
          <div className="row " style={{ position: "relative" }}>
            <div
              style={{ zIndex: 0 }}
              className="col-xl-5 col-lg-6 col-md-8 order-lg-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner pt-lg-3">
                {/* <img
                  style={{ display: "none" }}
                  src="assets/img/about/the_way_we_work.png"
                  id="after-all-collapse"
                  alt="img"
                /> */}
              </div>
            </div>
            <div
              style={{ zIndex: 1 }}
              className="col-xl-7 col-lg-6"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h6 className="sub-title">Our Approach</h6>
                <h2 className="title">
                  The <span>Way</span> We Work
                </h2>
                <p className="content">
                  Discover our innovative blend of technology and expertise for
                  swift and cost-effective dispute resolution.
                </p>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What we do?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body relative">
                      Presolv360 offers online dispute resolution (ODR)
                      services, including mediation and arbitration, providing a
                      convenient and efficient way to resolve conflicts
                      remotely. With a user-friendly interface and secure case
                      management system, we streamline the resolution process
                      and empower individuals and businesses to achieve
                      satisfactory outcomes.
                      <img
                        style={{
                          top: "-220px",
                        }}
                        className="main-img faq-image-position"
                        src="assets/img/about/the_way_we_work.png"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How it Works?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body relative">
                      One-time collection of data through secure channels.
                      Rigorous verification by our team to weed out
                      discrepancies. Establishing a channel of communication for
                      digital negotiation. Complete support for
                      arbitration/mediation on the ODR platform. Enforceable
                      outcomes for record recoveries.
                      <img
                        style={{
                          top: "-280px",
                        }}
                        className="main-img faq-image-position"
                        src="assets/img/about/how_it_works.png"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Why Choose Us?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body relative">
                      Presolv360 has been included as an ADR/ODR institution in
                      the notification issued by the Department of Legal
                      Affairs, Ministry of Law & Justice, and has been
                      empanelled as a Mediation Institute by the Main Mediation
                      Centre, Bombay High Court and the Maharashtra Legal
                      Services Authority. The platform has administered 82,000+
                      disputes registered by 60+ enterprises, involving over INR
                      4,800 crores in disputed value and 4,70,000 parties.
                      <img
                        className="main-img faq-image-position"
                        style={{
                          top: "-350px",
                        }}
                        src="assets/img/about/why_choose_us.png"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default FaqAreaOne;
