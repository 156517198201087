import React from 'react';

import BrandAreaOne from '../components/BrandAreaOne';
import Breadcrumb from '../components/Breadcrumb';
import CareersArea from '../components/CareersArea';
import FooterOne from '../components/FooterOne';
import NavBar from '../components/NavBar';
import ServiceAreaGroup from '../components/ServiceAreaGroup';

const Careers = () => {
  return (
    <>
      <NavBar />
      <Breadcrumb title={"Careers"} />
      <CareersArea />

      <ServiceAreaGroup />
      <BrandAreaOne />
      <FooterOne />
    </>
  );
};

export default Careers;
