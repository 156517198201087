import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import React, { useEffect, useState } from "react";
import { FaCalendarAlt, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const GloballySpeaking = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maxHeight, setMaxHeight] = useState(0);
  const setEqualHeight = () => {
    const divs = document.querySelectorAll(".details");
    const maxHeight = Array.from(divs).reduce(
      (max, div) => Math.max(max, div.clientHeight),
      0
    );
    setMaxHeight(maxHeight);
  };
  useEffect(() => {
    setTimeout(setEqualHeight, 1000);
  }, []);

  useEffect(() => {
    // Define your API URL
    const apiUrl =
      "https://presolv360.com/resources/wp-json/custom-apis/v1/get-posts?category=gobally-speaking";

    // Make the API request
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setBlogData(data); // Set the fetched data to the state variable
        setLoading(false); // Update loading state to false
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false); // Update loading state to false in case of an error
      });
  }, []); // Empty dependency array to run this effect only once

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    clone: false,
    slidesToShow: 3, // Display 3 slides at once
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Display 1 slide at a time for smaller screens (e.g., mobile)
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {/* ... Your existing JSX for the component ... */}
      <div className="blog-area  pd-top-60 " style={{ paddingBottom: "30px" }}>
        <div className="container container-767">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                {/* <h6 className="sub-title">Resources</h6> */}
                <h2 className="title">Globally Speaking</h2>
              </div>
            </div>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Slider {...sliderSettings}>
              {blogData.map((post) => (
                <div key={post.id}>
                  <div className="single-blog-list style-3">
                    {/* Render the blog post data here */}
                    <div className="thumb">
                      {post.featured_image ? (
                        <img
                          src={post.featured_image}
                          alt="img"
                          className="full-width"
                        />
                      ) : (
                        <img
                          src="assets/img/blog/Resources1.jpg"
                          alt="Default Image"
                          className="full-width"
                        />
                      )}
                    </div>
                    <div className="details" style={{ minHeight: maxHeight }}>
                      <ul className="blog-meta">
                        <li>
                          {/* <span>
                            <img src="assets/img/blog/author.svg" alt="img" />
                          </span> */}
                          {post.category}
                        </li>
                        <li>
                          <FaCalendarAlt /> {post.date.split(" ")[0]}
                        </li>
                      </ul>
                      <h5 className="mb-3">
                        <Link to={post.post_link}>{post.title}</Link>
                      </h5>
                      <p>{post.description}</p>
                      <div className="btn-wrap text-end pe-2">
                        <Link className="read-more-text" to={post.post_link}>
                          Read More <FaPlus />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
      {/* ... Your existing JSX for the component ... */}
    </>
  );
};

export default GloballySpeaking;
