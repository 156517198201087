import "aos/dist/aos.css";

import AOS from "aos";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Careers from "./pages/Careers";
import CaseStudyDetails from "./pages/CaseStudyDetails";
import Contact from "./pages/Contact";
import CookiePolicy from "./pages/CookiePolicy";
import Faq from "./pages/Faq";
import Fees from "./pages/Fees";
import HomeFive from "./pages/HomeFive";
import HomeFour from "./pages/HomeFour";
import HomeOne from "./pages/HomeOne";
import HomeThree from "./pages/HomeThree";
import HomeTwo from "./pages/HomeTwo";
import HowItWorks from "./pages/HowItWorks";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Resources from "./pages/Resources";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Standards from "./pages/Standards";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import TermsAndConditions from "./pages/TermsAndConditions";
import WhatWeDo from "./pages/WhatWeDo";
import WhyUs from "./pages/WhyUs";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomeOne />} />
        <Route exact path="/index-2" element={<HomeTwo />} />
        <Route exact path="/index-3" element={<HomeThree />} />
        <Route exact path="/index-4" element={<HomeFour />} />
        <Route exact path="/index-5" element={<HomeFive />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/what-we-do" element={<WhatWeDo />} />
        <Route exact path="/how-it-works" element={<HowItWorks />} />
        <Route exact path="/why-us" element={<WhyUs />} />
        <Route exact path="/resources" element={<Resources />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog-details" element={<BlogDetails />} />
        <Route
          exact
          path="/case-study-details"
          element={<CaseStudyDetails />}
        />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/careers" element={<Careers />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/service-details" element={<ServiceDetails />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/fees" element={<Fees />} />
        <Route
          exact
          path="/terms_conditions"
          element={<TermsAndConditions />}
        />
        <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route exact path="/cookie_policy" element={<CookiePolicy />} />
        <Route exact path="/odr_standard" element={<Standards />} />
        <Route exact path="/" element={<TeamDetails />} />
      </Routes>
      <ScrollToTop smooth color="#246BFD" />
    </BrowserRouter>
  );
}

export default App;
