import React from "react";

const WorkProcessFour = () => {
  return (
    <>
      {/*==================== Work Process Three start ====================*/}
      <div
        className="work-process-area bg-position-right pd-top-60 pd-bottom-60"
        style={{ backgroundImage: 'url("./assets/img/bg/16.png")' }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div
                className="section-title text-center pb-5"
                style={{ marginBottom: "15px" }}
              >
                <h6 className="sub-title">Work Process</h6>
                <h2 className="title" style={{ wordSpacing: "5px" }}>
                  Our <span>Milestones</span>
                </h2>
                <p
                  class="content pe-xl-5 aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                >
                  Revolutionizing dispute resolution, Presolv360 has achieved
                  significant milestones by expanding services, collaborating
                  with legal institutions, and integrating with other platforms.
                </p>
              </div>
            </div>
          </div>
          <div className="work-process-area-inner-2">
            <div className="row">
              {/* <div className="time-line"></div> */}
              <div className="tw-width col-md-6 white-transparent">
                <div className="single-work-process-inner style-2 text-center">
                  {/* <div className="circle"></div> */}
                  <img
                    className="line-img"
                    src="assets/img/about/29.png"
                    alt="img"
                  />
                  <div className="thumb mb-3">
                    {/* <img src="assets/img/icon/36.svg" alt="img" /> */}
                  </div>
                  <div className="details">
                    <h5 className="mb-3">2017</h5>
                    <p className="content">
                      Blending research, innovation, tech, and empathy,
                      Presolv360 was founded to make dispute resolution simple
                      and user-centric
                    </p>
                  </div>
                </div>
              </div>
              <div className="tw-width col-md-6 white-transparent">
                <div className="single-work-process-inner style-2 text-center">
                  <img
                    className="line-img"
                    src="assets/img/about/29.png"
                    alt="img"
                  />
                  <div className="thumb mb-3">
                    {/* <img src="assets/img/icon/37.svg" alt="img" /> */}
                  </div>
                  <div className="details">
                    <h5 className="mb-3">2020</h5>
                    <p className="content">
                      Included as an ADR/ODR institution in the notification
                      issued by Department of Legal Affairs, Ministry of Law and
                      Justice
                    </p>
                  </div>
                </div>
              </div>
              <div className="tw-width col-md-6 white-transparent">
                <div className="single-work-process-inner style-2 text-center">
                  <img
                    className="line-img"
                    src="assets/img/about/29.png"
                    alt="img"
                  />
                  <div className="thumb mb-3">
                    {/* <img src="assets/img/icon/38.svg" alt="img" /> */}
                  </div>
                  <div className="details">
                    <h5 className="mb-3">2021</h5>
                    <p className="content">
                      Empanelled as Mediation Institute by Main Mediation
                      Centre, Bombay High Court and Maharashtra Legal Services
                      Authority
                    </p>
                  </div>
                </div>
              </div>
              <div className="tw-width col-md-6 white-transparent">
                <div className="single-work-process-inner style-2 text-center">
                  <img
                    className="line-img"
                    src="assets/img/about/29.png"
                    alt="img"
                  />
                  <div className="thumb mb-3">
                    {/* <img src="assets/img/icon/39.svg" alt="img" /> */}
                  </div>
                  <div className="details">
                    <h5 className="mb-3">2022 </h5>
                    <p className="content">
                      Incubated by Cyril Amarchand Mangaldas and backed by
                      marquee investors like Omidyar Network India, MGA Ventures
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="tw-width col-md-6 white-transparent">
                <div className="single-work-process-inner style-2 text-center">
                  <img
                    className="line-img"
                    src="assets/img/about/29.png"
                    alt="img"
                  />
                  <div className="thumb mb-3">
                    {/* <img src="assets/img/icon/39.svg" alt="img" /> */}
                  </div>
                  <div className="details">
                    <h5 className="mb-3">2023</h5>
                    <p className="content">
                      At the onset, parties in 7,257 pin codes saved INR 362
                      million in costs and 7.8 million days following record
                      participation and settlements
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default WorkProcessFour;
