import React from "react";
import { Link } from "react-router-dom";

const FaqAreaOne = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div
        className="faq-area faq-area-margin-top bg-cover pd-top-90  pd-bottom-90"
        // style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="container">
          <div className="row pd-top-60">
            <div
              className="col-xl-12 col-lg-12"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is Presolv360?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Presolv360 is a neutral and independent platform providing
                      Alternative Dispute Resolution (ADR) as well as Online
                      Dispute Resolution (ODR) services via its proprietary
                      dispute resolution modules.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What is ODR?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      ODR involves using legally recognized ADR mechanisms to
                      resolve disputes in an online environment. It is a digital
                      counterpart of ADR that uses technology to resolve
                      disputes out-of-court through various mechanisms such as
                      arbitration, conciliation, mediation, negotiation, or a
                      combination thereof (collectively referred to as ADR
                      Process). It has the potential to solve for one of the
                      most challenging problems faced by humanity – ‘access to
                      justice’.{" "}
                      <Link
                        className="links"
                        to="https://drive.google.com/file/d/13kDlOAF414cSrDjt7yYNsWbP4JMb8Fog/view?pli=1"
                      >
                        Click here
                      </Link>{" "}
                      to access Presolv360’s concept note on ODR.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div
              className="col-xl-12 col-lg-12"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 mt-4 mt-lg-0"></div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="true"
                      aria-controls="collapseSeven"
                    >
                      Why choose Presolv360?
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    {" "}
                    <div className="row ">
                      <div className="accordion-body">
                        Presolv360 is a pioneer of ODR in the country. It is one
                        of the only ODR platforms to be included in the list of
                        institutions offering ADR services including ODR (
                        <Link
                          className="links"
                          to="https://drive.google.com/file/d/1T7D2z6Y0eeRuXCdCHjiYjg2AQ4qYEQ6P/view"
                        >
                          click here
                        </Link>{" "}
                        to view an extract of the notification) and be
                        empanelled by a High Court in the country. It has also
                        been incubated by India’s leading law firm Cyril
                        Amarchand Mangaldas. Presolv360 is advised by legal
                        luminaries such as Justice (Retd.) B N Srikrishna,
                        Justice (Retd.) Pradeep Nandrajog, and Justice (Retd.) K
                        Kannan, among others. It is constantly working with
                        various stakeholders, including the judiciary and the
                        government, to make dispute resolution accessible,
                        affordable, and convenient. Presolv360 is resolving
                        disputes for some of India's largest business
                        institutions and conglomerates and has become the
                        industry standard for ODR.
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      What is the role of Presolv360 in resolving disputes?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Presolv360 does not undertake any adjudicatory functions
                      and only provides administrative assistance for the
                      conduct of the ADR Process on its virtual platform. It
                      empanels independent, qualified neutrals with the required
                      competence, knowledge, and expertise on its panel. Being a
                      neutral and independent platform, Presolv360 has no
                      interest in the outcome of the dispute or any conflict of
                      interest with the dispute or the disputing parties.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div
              className="col-xl-12 col-lg-12"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Does Presolv360 have a model clause and rules governing
                      the dispute resolution proceedings?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Link
                        className="links"
                        to="https://presolv360.com/dispute_resolution_clause"
                      >
                        click here
                      </Link>{" "}
                      to view the model clause.{" "}
                      <Link
                        className="links"
                        to="https://presolv360.com/important_documents"
                      >
                        click here
                      </Link>{" "}
                      to access the governing documents for resolving disputes
                      on Presolv360’s ODR platform.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      How are the disputes resolved on Presolv360’s ODR
                      platform?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Presolv360 administers dispute resolution via its
                      proprietary modules, ‘Arbitration360’ and ‘Mediation360’.{" "}
                      <Link
                        className="links"
                        to="https://drive.google.com/file/d/13kDlOAF414cSrDjt7yYNsWbP4JMb8Fog/view?pli=1"
                      >
                        Click here
                      </Link>{" "}
                      to watch an explainer video. Arbitration360 refers to
                      Presolv360’s electronic arbitration module wherein
                      disputing parties are bound by an award made by the
                      arbitrator. Parties opt for arbitration in the event they
                      desire a decision by an independent adjudicator.
                      Mediation360 refers to Presolv360’s electronic mediation
                      module wherein disputing parties mutually opt to settle
                      the dispute with the help of an independent neutral.
                      Parties shall opt for mediation in the event they desire a
                      negotiated settlement. The Mediation360 module can also be
                      used to facilitate conciliations, negotiations, or a
                      combination thereof.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      How do I provide for ODR in my contract?
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      To incorporate ODR as a dispute resolution mechanism in
                      your contract:  Insert an appropriate clause under the
                      heading ‘Dispute Resolution’ or under a similar section in
                      the contract; [Please ensure that there are no conflicting
                      dispute resolution clauses under the heading “Dispute
                      Resolution” or “Arbitration” or under a similar section in
                      the contract. In case the contract is already executed,
                      parties can still opt for ODR mechanisms by agreeing to it
                      by an exchange of letters/emails.]  The ‘Notice’ clause
                      in the contract should enable parties to serve
                      notices/communications by email, text message, and
                      tele-messaging applications such as WhatsApp; and  The
                      ‘Governing Law’ clause in the contract states that the
                      contract is governed by and construed in accordance with
                      the laws prevalent in India.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      What are the main features of Presolv360’s ODR platform?
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          Cloud-based end-to-end ODR platform accessible from
                          desktops, laptops, and smartphones
                        </li>
                        <li> Smart case management tools</li>
                        <li>
                          Email, WhatsApp, and SMS integration along with
                          real-time tracking
                        </li>
                        <li>Automated phone system technology</li>
                        <li>
                          Automated case allocation tool to randomize the
                          process of assignment of neutrals
                        </li>
                        <li>Automated document workflow</li>
                        <li>Pre-formatted documents</li>
                        <li>E-signatures and e-stamping</li>
                        <li>Automated settlement builders</li>
                        <li>Blockchain-based trace trail of proceedings</li>
                        <li>Machine-intelligent chatbots</li>
                        <li>Auto-generated reports on dispute trends</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingEight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      How can arbitrators or mediators empanel themselves with
                      Presolv360?
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The empanelment is based on profile verification,
                      tutorials, training, and performance evaluation. Such
                      empanelment does not entail any fee. Drop an email to{" "}
                      <Link className="links" to="mailto:info@presolv360.com">
                        info@presolv360.com
                      </Link>{" "}
                      to begin the empanelment process.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingNine">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      When can a dispute be resolved on Presolv360’s ODR
                      platform?
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingNine"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      A dispute can be resolved on Presolv360’s ODR platform
                      when:
                      <ul>
                        <li>
                          The contract contains an appropriate dispute
                          resolution clause;
                        </li>
                        <li>
                          An invitation to resolve on Presolv360’s ODR platform
                          is accepted;
                        </li>
                        <li>Parties make a joint application; or</li>
                        <li>
                          Dispute is referred by a court, tribunal, or
                          regulatory authority.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      Can a dispute be resolved on Presolv360’s ODR platform
                      without an ADR or ODR clause?
                    </button>
                  </h2>
                  <div
                    id="collapseTen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Yes. Once a dispute is registered on Presolv360’s ODR
                      platform by a party, an invitation will be sent by
                      Presolv360 to the other party. Once accepted, the ADR
                      Process will commence on Presolv360’s ODR platform.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingEleven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEleven"
                      aria-expanded="false"
                      aria-controls="collapseEleven"
                    >
                      How will notices, updates, information, and documents be
                      communicated to the parties?
                    </button>
                  </h2>
                  <div
                    id="collapseEleven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEleven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      All notices, updates, information, and documents will be
                      communicated in real-time via email and messaging
                      applications such as SMS/WhatsApp and can also be viewed
                      in real-time by logging in to Presolv360’s ODR platform.{" "}
                      <Link
                        className="links"
                        to="https://drive.google.com/file/d/1r9VrmyKjOW6Ph4TpndE7mA7fBqgLvWSm/view?usp=sharing"
                      >
                        Click here
                      </Link>{" "}
                      to read about the service through emails, WhatsApp, and
                      messaging applications.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwelve">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwelve"
                      aria-expanded="false"
                      aria-controls="collapseTwelve"
                    >
                      How will oral hearings be conducted?
                    </button>
                  </h2>
                  <div
                    id="collapseTwelve"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwelve"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Oral hearings can be conducted virtually on
                      party-preferred audio/video-conferencing applications.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThirteen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThirteen"
                      aria-expanded="false"
                      aria-controls="collapseThirteen"
                    >
                      What are the fees to resolve a dispute on Presolv360’s ODR
                      platform?
                    </button>
                  </h2>
                  <div
                    id="collapseThirteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThirteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The fees will be determined on the basis of the number of
                      parties, the quantum of the claim, and the complexity of
                      the dispute. To know the fee applicable to you, contact us
                      at{" "}
                      <Link className="links" to="mailto:info@presolv360.com">
                        info@presolv360.com
                      </Link>
                      .
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFourteen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourteen"
                      aria-expanded="false"
                      aria-controls="collapseFourteen"
                    >
                      How long does resolving a dispute on Presolv360’s ODR
                      platform take?
                    </button>
                  </h2>
                  <div
                    id="collapseFourteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFourteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Depending on the dispute and the mechanism employed,
                      resolutions have been achieved in as little as three
                      hours, to an average of 27 days.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFifteen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFifteen"
                      aria-expanded="false"
                      aria-controls="collapseFifteen"
                    >
                      What do I do when I receive a notice/invitation from
                      Presolv360?
                    </button>
                  </h2>
                  <div
                    id="collapseFifteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFifteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Presolv360 provides 24/7 administrative support to each
                      party. In case you have received a notice from Presolv360,
                      and you would like to respond, negotiate, or propose a
                      settlement, drop an email to{" "}
                      <Link className="links" to="mailto:admin@presolv360.com">
                        admin@presolv360.com
                      </Link>{" "}
                      and include your case ID in the subject line.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSixteen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixteen"
                      aria-expanded="false"
                      aria-controls="collapseSixteen"
                    >
                      Can Presolv360 provide legal advice in respect of
                      disputes?
                    </button>
                  </h2>
                  <div
                    id="collapseSixteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSixteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Presolv360 is not a law firm and does not provide legal
                      advice. Only a legal practitioner can provide legal
                      advice. A legal practitioner should be consulted for any
                      legal advice or matter.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSeventeen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeventeen"
                      aria-expanded="false"
                      aria-controls="collapseSeventeen"
                    >
                      How will Presolv360 assign an arbitrator or mediator to
                      the dispute?
                    </button>
                  </h2>
                  <div
                    id="collapseSeventeen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeventeen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Upon registration of a dispute, a neutral is assigned on
                      behalf of all the parties after evaluating various factors
                      such as:
                      <ul>
                        <li>Dispute: nature, complexity, and quantum</li>
                        <li>
                          Neutral: area of expertise, availability, and ability
                          to conduct the proceedings neutrally and fairly
                        </li>
                        <li>Parties: independence with the neutral</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingEighteen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEighteen"
                      aria-expanded="false"
                      aria-controls="collapseEighteen"
                    >
                      Can parties be represented by an authorized representative
                      or a lawyer in the resolution proceedings?
                    </button>
                  </h2>
                  <div
                    id="collapseEighteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEighteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Parties may choose to be represented or assisted through
                      an authorized representative for the proceedings. However,
                      the professional charges of the authorized representative,
                      cost of appointment and representation, or any other
                      incidental costs shall be borne by the appointing party.
                      In relation to the arbitration proceedings, the arbitrator
                      will have the discretion to determine whether costs are
                      payable by one party to another, the amount of such costs,
                      and when such costs are to be paid.{" "}
                      <Link to="https://drive.google.com/file/d/1Q1d6_3n3R1QimVhtb1eGFC2jG3cWk7pS/view">
                        Click here
                      </Link>{" "}
                      to download the format of the Letter of Authority.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingNineteen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNineteen"
                      aria-expanded="false"
                      aria-controls="collapseNineteen"
                    >
                      What happens if the responding party does not participate
                      in the proceedings?
                    </button>
                  </h2>
                  <div
                    id="collapseNineteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingNineteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Mediation/conciliation/negotiation proceedings are
                      voluntary in nature and require consent and participation
                      of all parties to the dispute. In this case,
                      non-participation would render the proceedings a
                      non-starter and the matter will not be proceeded with. In
                      case of arbitration, notwithstanding non-participation by
                      a party, the arbitration proceedings may continue, and the
                      arbitrator may proceed with the adjudication of the
                      dispute based on the evidence before the arbitrator. It is
                      recommended that all parties should participate in the
                      proceedings for proper resolution of the dispute.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwenty">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwenty"
                      aria-expanded="false"
                      aria-controls="collapseTwenty"
                    >
                      Is the outcome legally binding and enforceable?
                    </button>
                  </h2>
                  <div
                    id="collapseTwenty"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwenty"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      As per the Arbitration and Conciliation Act, 1996, an
                      arbitration award is final and binding on the parties and
                      enforceable as if it were a decree of the court. A
                      settlement agreement made in pursuance of mediation
                      proceedings shall be final and binding on the parties. The
                      terms of settlement may also be recorded in a conciliation
                      agreement, and it shall have the same status and effect as
                      if it is an arbitral award.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwentyOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwentyOne"
                      aria-expanded="false"
                      aria-controls="collapseTwentyOne"
                    >
                      What happens after an arbitral award is passed?
                    </button>
                  </h2>
                  <div
                    id="collapseTwentyOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwentyOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Parties are required to carry out the arbitral award
                      immediately and without delay to avoid serious
                      consequences as provided under the law. A challenge to an
                      arbitral award may be made by approaching a court of
                      competent jurisdiction within the prescribed time limit.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwentyTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwentyTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwentyTwo"
                    >
                      Can parties communicate directly with the assigned
                      neutral?
                    </button>
                  </h2>
                  <div
                    id="collapseTwentyTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwentyTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      To maintain integrity and independence of the ADR Process,
                      parties shall not unilaterally communicate with the
                      assigned neutral and the neutral shall not communicate
                      unilaterally with any party. Any and all communication
                      with the neutral shall be through Presolv360.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwentyThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwentyThree"
                      aria-expanded="false"
                      aria-controls="collapseTwentyThree"
                    >
                      Is it safe and secure to conduct dispute resolution
                      proceedings online?
                    </button>
                  </h2>
                  <div
                    id="collapseTwentyThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwentyThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Presolv360 has implemented appropriate safeguards to
                      prevent unauthorized access to any party’s information or
                      documents and to maintain data security. The company
                      employs encryption technology for protecting sensitive
                      information. Presolv360 follows generally accepted
                      industry standards to protect the information and
                      documents submitted on Presolv360’s ODR platform. However,
                      no method of electronic transmission or storage is 100%
                      secure. While Presolv360 strives to use commercially
                      acceptable means to protect the information and documents
                      of the parties, it does not guarantee its absolute
                      security and assumes no liability or responsibility for
                      disclosure of any party’s information due to errors in
                      transmission, unauthorized third-party access, or other
                      causes beyond Presolv360’s control. The parties play an
                      important role in keeping their information secure.
                      Parties should not share their account information with
                      any third party.{" "}
                      <Link
                        className="links"
                        href="https://presolv360.com/odr_standard"
                      >
                        Click here
                      </Link>{" "}
                      to view the ODR standards adopted by Presolv360.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwentyFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwentyFour"
                      aria-expanded="false"
                      aria-controls="collapseTwentyFour"
                    >
                      Are the resolution proceedings confidential?
                    </button>
                  </h2>
                  <div
                    id="collapseTwentyFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwentyFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      While the platform, technology, and process have been
                      designed and developed keeping in mind confidentiality
                      obligations applicable to dispute resolution, the
                      applicable laws bind the parties, practitioners, and
                      providers to confidentiality. Accordingly, all proceedings
                      facilitated and administered on Presolv360’s ODR platform
                      are confidential except where disclosure is necessary for
                      purposes of implementation and enforcement.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwentyFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwentyFive"
                      aria-expanded="false"
                      aria-controls="collapseTwentyFive"
                    >
                      Is ODR effective and what are its benefits?
                    </button>
                  </h2>
                  <div
                    id="collapseTwentyFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwentyFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      ODR proceedings entail the same validity and
                      enforceability as the ADR Process. Further, ODR has
                      already brought tremendous value to parties and experts
                      for a variety of disputes as it is a quick, convenient,
                      and cost-effective way of resolving them.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default FaqAreaOne;
