import React from "react";

import BlogAreaFive from "../components/BlogAreaFive";
import BlogOpinions from "../components/BlogOpinions";
import BrandAreaOne from "../components/BrandAreaOne";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import GloballySpeaking from "../components/GloballySpeaking";
import NavBar from "../components/NavBar";
import ResearchPapers from "../components/ResearchPapers";

const Resources = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />
      {/* Navigation Bar */}
      <Breadcrumb title={"Resources"} />
      {/* About Area One */}
      <BlogAreaFive />
      <BlogOpinions />

      <ResearchPapers />

      <GloballySpeaking />
      <BrandAreaOne />
      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default Resources;
