import React from 'react';

import BrandAreaOne from '../components/BrandAreaOne';
import Breadcrumb from '../components/Breadcrumb';
import FaqArea from '../components/FaqArea';
import FooterOne from '../components/FooterOne';
import NavBar from '../components/NavBar';

const About = () => {
  return (
    <>
      <NavBar />
      <Breadcrumb title={"FAQ's"} />
      <FaqArea />
      <BrandAreaOne />
      <FooterOne />
    </>
  );
};

export default About;
