import React from "react";

import AboutAreaOne from "../components/AboutAreaOne";
import BannerOne from "../components/BannerOne";
import BlogAreaFive from "../components/BlogAreaFive";
import BrandAreaOne from "../components/BrandAreaOne";
import FaqAreaOne from "../components/FaqAreaOne";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import TeamAreaOne from "../components/TeamAreaOne";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessFour from "../components/WorkProcessFour";

const HomeOne = () => {
  return (
    <>
      <NavBar />
      <BannerOne />
      <BrandAreaOne />
      <AboutAreaOne />
      <WorkProcessFour />
      <FaqAreaOne />
      <TeamAreaOne />
      <TestimonialOne />
      <BlogAreaFive />
      <FooterOne />
    </>
  );
};

export default HomeOne;
