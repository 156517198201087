import React, { useEffect, useState } from "react";

const CyclingDotsTitle = () => {
  const [dots, setDots] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => (dots % 3) + 1);
    }, 800); // Change the interval as needed

    return () => clearInterval(interval);
  }, []);

  return <span>{".".repeat(dots)}</span>;
};

export default CyclingDotsTitle;
