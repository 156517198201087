import React, { useEffect, useState } from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const TeamAreaOne = () => {
  const [maxHeight, setMaxHeight] = useState(0);
  const setEqualHeight = () => {
    const divs = document.querySelectorAll(".single-team-inner");
    const maxHeight = Array.from(divs).reduce(
      (max, div) => Math.max(max, div.clientHeight),
      0
    );
    setMaxHeight(maxHeight);
  };
  useEffect(() => {
    setTimeout(setEqualHeight, 1000);
  }, []);
  return (
    <>
      {/*====================== team area start ======================*/}
      <div className="team-area bg-relative pd-top-60 pd-bottom-60">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title ">
              Meet the <span>Founders</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div
                className="single-team-inner text-center"
                style={{ minHeight: maxHeight }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/founders/Bhaven Shah.jpg"
                    className="founders-width"
                    alt="img"
                  />
                  <ul className="team-social-inner">
                    <li>
                      <Link
                        to="https://www.facebook.com/shahbhaven"
                        target="_blank"
                      >
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://twitter.com/bhavents" target="_blank">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.linkedin.com/in/bhaven-shah/"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/">Bhaven Shah</Link>
                  </h5>
                  <p>Head - Legal & Strategy</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-team-inner text-center"
                style={{ minHeight: maxHeight }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/founders/Namita Shah1.jpg"
                    className="founders-width"
                    alt="img"
                  />
                  <ul className="team-social-inner">
                    <li>
                      <Link
                        to="https://www.facebook.com/namita.shah.710"
                        target="_blank"
                      >
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://twitter.com/Namitavs" target="_blank">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.linkedin.com/in/namita-shah17/"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/">Namita Shah</Link>
                  </h5>
                  <p>Head - Product & Finance</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-team-inner text-center"
                style={{ minHeight: maxHeight }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/founders/Aman Sanghavi.jpg"
                    className="founders-width"
                    alt="img"
                  />
                  <ul className="team-social-inner">
                    <li>
                      <Link
                        to="https://www.facebook.com/aman.sanghavi"
                        target="_blank"
                      >
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://twitter.com/sanghaviaman"
                        target="_blank"
                      >
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.linkedin.com/in/aman-sanghavi/"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/">Aman Sanghavi</Link>
                  </h5>
                  <p>Head - Business & Operations</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================== team area end ======================*/}
    </>
  );
};

export default TeamAreaOne;
