import React from 'react';

const CookiePolicyContent = () => {
  return (
    <div class="container">
      <h4 className="multi-title">Cookie Policy</h4>
      <p className="multi-paragraph">
        The Cookies Notice explains how Presolv360 uses cookies and similar
        technologies in the course of our business, through our website{" "}
        <a href="https://www.presolv360.com/">Presolv360</a>. It explains what
        these technologies are, and why we use them, as well as your rights to
        control our use of them. &nbsp;
      </p>
      <h4 className="multi-title">1. What is a cookie? </h4>
      <p className="multi-paragraph">
        Cookies are small text files that a website asks the user’s browser to
        save on the user’s system/device, when a user visits the website, in
        order to remember the user’s device upon revisiting the website or
        during the session. Cookies set up by the domain{" "}
        <a href="https://www.presolv360.com/">Presolv360</a>, are called
        first-party cookies and cookies set by other domain(s) than the
        website(s) you are visiting are termed as third-party cookies.
        Third-party cookies are generally used for marketing and advertising
        purposes.{" "}
      </p>
      <h4 className="multi-title">2. Purpose of using cookies?</h4>
      <p className="multi-paragraph">
        Our website <a href="https://www.presolv360.com/">Presolv360</a> uses
        cookies and other tracking technologies for the following purposes:{" "}
      </p>

      <ul>
        <li>To analyze your use of our website </li>
      </ul>
      <ul>
        <li>For promotional and marketing purposes </li>
      </ul>
      <ul>
        <li>To understand your preferences such as preferred language </li>
      </ul>
      <ul>
        <li>To deliver information that matches your interests</li>
      </ul>
      <ul>
        <li>To help us understand our audience and traffic patterns</li>
      </ul>
      <ul>
        <li>
          To let you automatically log into programs and parts of our site that
          require membership
        </li>
      </ul>
      <ul>
        <li>To manage and present the information displayed on our website </li>
      </ul>
      <h4 className="multi-title">3. Type of cookies used by Presolv360 </h4>
      <ul>
        <li>Strictly Necessary Cookies </li>
      </ul>
      <ul>
        <li>Targeting Cookies </li>
      </ul>
      <ul>
        <li>Functionality Cookies </li>
      </ul>
      <ul>
        <li>Performance Cookies </li>
      </ul>
      <h4 className="multi-title">4. Cookie list </h4>
      <p className="multi-paragraph">
        The table below provides additional details on the specific cookies used
        on <a href="https://www.presolv360.com/">Presolv360</a>:{" "}
      </p>
      <ul>
        <li>
          <u>Strictly Necessary Cookies - </u>Some cookies are required for
          technical reasons in order for our websites to support functions such
          as logging in, and payment transactions.
        </li>
      </ul>

      <div class="table-responsive">
        <table
          class="table table-bordered table-hover"
          style={{ margin: "2rem 0" }}
        >
          <tbody>
            <tr>
              <td>Cookie Provider</td>
              <td>Name of Cookie</td>
              <td>Type of Cookie</td>
              <td>Purpose</td>
              <td>Duration (days)</td>
            </tr>
            <tr>
              <td>www.Presolv360</td>
              <td>_presolv_visit</td>
              <td>First-party</td>
              <td>
                Cookie associated with sites for used to identify user cookie
                policy accepted or not
              </td>
              <td>1 Day</td>
            </tr>
            <tr>
              <td>www.Presolv360</td>
              <td>PHPSESSID</td>
              <td>First-party</td>
              <td>
                This cookie name is associated with the website used by PHP.
                This cookie is used to provide functions across pages.
              </td>
              <td>Session</td>
            </tr>
          </tbody>
        </table>
      </div>

      <ul>
        <li>
          <u>Targeted Cookies - </u>These cookies may be set through our site by
          our advertising partners. They may be used by those companies to build
          a profile of your interests and show you relevant adverts on other
          sites. They do not store direct personal information but are based on
          uniquely identifying your browser and internet device.
        </li>
      </ul>

      <div class="table-responsive">
        <table
          class="table table-bordered table-hover"
          style={{ margin: "2rem 0" }}
        >
          <tbody>
            <tr>
              <td>Cookie Provider</td>
              <td>Name of Cookie </td>
              <td>Type of Cookie</td>
              <td>Purpose </td>
              <td>Duration (days)</td>
            </tr>
            <tr>
              <td>www.Presolv360</td>
              <td>_gcl_au</td>
              <td>First-party</td>
              <td>
                Used by Google AdSense for experimenting with advertisement
                efficiency across websites using their services
              </td>
              <td>Persistent</td>
            </tr>
            <tr>
              <td>www.Presolv360</td>
              <td>_ga</td>
              <td>First-party</td>
              <td>
                Used by Google Analytics for store and count pageviews across
                websites using their services
              </td>
              <td>2 Year</td>
            </tr>

            <tr>
              <td>www.Presolv360</td>
              <td>_gat_gtag_UA_*</td>
              <td>First-party</td>
              <td>
                Used by Google Analytics for to store a unique user ID on this
                website
              </td>
              <td>Sesion</td>
            </tr>
          </tbody>
        </table>
      </div>

      <ul>
        <li>
          <u>Functionality Cookies - </u>Some cookies are required for technical
          reasons in order for our websites to support functions such as logging
          in, and payment transactions.
        </li>
      </ul>

      <div class="table-responsive">
        <table
          class="table table-bordered table-hover"
          style={{ margin: "2rem 0" }}
        >
          <tbody>
            <tr>
              <td>Cookie Provider</td>
              <td>Name of Cookie </td>
              <td>Type of Cookie</td>
              <td>Purpose </td>
              <td>Duration (days)</td>
            </tr>
            <tr>
              <td>www.Presolv360</td>
              <td>name</td>
              <td>First-party</td>
              <td>
                This cookie name is associated with website used to Split cookie
                string and get all individual name=value pairs in an array.
              </td>
              <td>Session</td>
            </tr>
          </tbody>
        </table>
      </div>

      <ul>
        <li>
          <u>Performance Cookies - </u>Performance cookies are cookies used
          specifically for gathering data on how visitors use a website, which
          pages of a website are visited most often, or if they get error
          messages on web pages. These cookies monitor only the performance of
          the site as the user interacts with it. These cookies don’t collect
          identifiable information on visitors, which means all the data
          collected is anonymous and only used to improve the functionality of a
          website.
        </li>
      </ul>

      <div class="table-responsive">
        <table
          class="table table-bordered table-hover"
          style={{ margin: "2rem 0" }}
        >
          <tbody>
            <tr>
              <td>Cookie Provider</td>
              <td>Name of Cookie </td>
              <td>Type of Cookie</td>
              <td>Purpose </td>
              <td>Duration (days)</td>
            </tr>
            <tr>
              <td>www.Presolv360</td>
              <td>_gid</td>
              <td>First-party</td>
              <td>
                Used by Google Analytics for store and count pageviews across
                websites using their services.
              </td>
              <td>1 Day</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p className="multi-paragraph">
        Any cookie, whose purpose has not been identified by us or is unknown to
        our current understanding, is classified together as “Unclassified
        Cookies”. Such cookies may or may not adhere to our policy and we cannot
        take ownership of said cookies, however, we would periodically and
        actively try to investigate them and categorize them in their respective
        cookie categories for the benefit of our users, partners and customers.
      </p>
      <h4 className="multi-title">
        5. Other tracking technologies like web beacons{" "}
      </h4>
      <p className="multi-paragraph">
        Cookies are not the only way to recognize or track visitors on a
        website. We may use other similar technologies from time to time, like
        web beacons (sometimes called “tracking pixels” or “clear gifs”). These
        are tiny graphics files that contain a unique identifier that enables us
        to recognize when someone has visited our websites or opened an e-mail
        including these. In many instances, these technologies are reliant on
        cookies to function properly, and hence, declining cookies will impair
        their functioning.
      </p>
      <h4 className="multi-title">
        6. How can I control the cookie preferences?{" "}
      </h4>
      <p className="multi-paragraph">
        Most browsers are set to accept cookies by default. However, you may
        remove or reject cookies in your browser’s settings. Removing or
        rejecting the cookies may affect the performance of the website. You may
        opt-out of the DoubleClick cookie by visiting the Google advertising
        opt-out page:{" "}
        <a href="http://www.google.com/policies/privacy/ads/">
          http://www.google.com/policies/privacy/ads/
        </a>
        . Or you may opt-out of Google Analytics by visiting the Google
        Analytics Opt-out page:{" "}
        <a href="http://tools.google.com/dlpage/gaoptout">
          http://tools.google.com/dlpage/gaoptout
        </a>
        .{" "}
      </p>
      <h4 className="multi-title">7. Update and Review </h4>
      <p className="multi-paragraph">
        We may update this Cookie Policy from time to time, therefore, we
        encourage you to periodically review this page for the latest
        information on the Policy.{" "}
      </p>
    </div>
  );
};

export default CookiePolicyContent;
