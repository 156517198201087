import React from "react";

import BrandAreaOne from "../components/BrandAreaOne";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import PrivacyPolicyContent from "../components/PrivacyPolicyContent";

const PrivacyPolicy = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Privacy Policy"} />

      <PrivacyPolicyContent />
      <BrandAreaOne />
      <FooterOne />
    </>
  );
};

export default PrivacyPolicy;
