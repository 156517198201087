import React from "react";

const MethodDR = () => {
  return (
    <>
      {/*==================== Work Process Three start ====================*/}
      <div
        className="work-process-area bg-position-right pd-top-60 pd-bottom-60"
        // style={{ backgroundImage: 'url("./assets/img/bg/16.png")' }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center ">
                <h6 className="sub-title">Process Flow</h6>
                <h2 className="title">
                  Our <span>Dispute Resolution</span> Journey
                </h2>
              </div>
            </div>
          </div>
          <div className="work-process-area-inner-2">
            <div className="row">
              <div className="col-lg-2_5 ">
                <div className="single-work-process-inner style-2 text-center">
                  <img
                    src="assets/img/about/29.png"
                    className="process-arows"
                    alt="img"
                  />
                  <div className="thumb mb-3">
                    <img src="assets/img/icon/Data inflow.svg" alt="img" />
                  </div>
                  <div className="details">
                    <h5 className="mb-3">Data inflow</h5>
                    <p className="content">
                      One-time collection of data through secure channels
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2_5 ">
                <div className="single-work-process-inner style-2 text-center">
                  <img
                    src="assets/img/about/29.png"
                    className="process-arows"
                    alt="img"
                  />
                  <div className="thumb mb-3">
                    <img src="assets/img/icon/Verification.svg" alt="img" />
                  </div>
                  <div className="details">
                    <h5 className="mb-3">Verification</h5>
                    <p className="content">
                      Rigorous verification by our team to weed out
                      discrepancies
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2_5 ">
                <div className="single-work-process-inner style-2 text-center">
                  <img
                    src="assets/img/about/29.png"
                    className="process-arows"
                    alt="img"
                  />
                  <div className="thumb mb-3">
                    <img src="assets/img/icon/Communication.svg" alt="img" />
                  </div>
                  <div className="details">
                    <h5 className="mb-3">Communication</h5>
                    <p className="content">
                      Establishing a channel of communication for digital
                      negotiation
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2_5 ">
                <div className="single-work-process-inner style-2 text-center">
                  <img
                    src="assets/img/about/29.png"
                    className="process-arows"
                    alt="img"
                  />
                  <div className="thumb mb-3">
                    <img src="assets/img/icon/Resolution.svg" alt="img" />
                  </div>
                  <div className="details">
                    <h5 className="mb-3">Resolution</h5>
                    <p className="content">
                      Complete support for arbitration/mediation on the ODR
                      platform
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2_5 ">
                <div
                  className="single-work-process-inner style-2 text-center"
                  style={{ minHeight: "auto" }}
                >
                  <img
                    src="assets/img/about/29.png"
                    className="process-arows"
                    alt="img"
                  />
                  <div className="thumb mb-3">
                    <img src="assets/img/icon/Outcome.svg" alt="img" />
                  </div>
                  <div className="details">
                    <h5 className="mb-3">Outcome</h5>
                    <p className="content">
                      Effective & enforceable outcomes for record recoveries
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default MethodDR;
