import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

const TeamAreaOne = () => {
  return (
    <>
      {/*====================== team area start ======================*/}
      <div
        className="team-area bg-position-right pd-top-60 pd-bottom-60 "
        style={{ backgroundImage: 'url("./assets/img/bg/16.png")' }}
      >
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Advisory Board</h6>
            <h2 className="title">
              Meet our <span>Panel of Experts</span>
            </h2>
          </div>
          <div className="row">
            {" "}
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/team/Justice Pradeep Nandrajog.png"
                    alt="img"
                  />
                  <ul className="team-social-inner">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/pradeep-nandrajog-446b4a1b5/"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link style={{ whiteSpace: "nowrap" }} to="/">
                      Justice Pradeep Nandrajog
                    </Link>
                  </h5>
                  <p>
                    Chief Justice (Retd.)<br></br> Bombay High Court  
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="assets/img/team/Justice K Kannan.png" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a href="#">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link
                      to="https://www.linkedin.com/in/kannan-krishnamoorthy-a85b0b1a/"
                      target="_blank"
                    >
                      {" "}
                      Justice K. Kannan
                    </Link>
                  </h5>
                  <p>
                    Judge (Retd.)<br></br> Punjab & Haryana High Court 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/team/Mr Srinath Sridharan.png"
                    alt="img"
                  />
                  <ul className="team-social-inner">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/srinathsridharan/"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/">Mr. Srinath Sridharan</Link>
                  </h5>
                  <p>
                    Corporate Leader,<br></br>CXO Coach
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="assets/img/team/Ms Tanu Mehta.png" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/tanu-mehta-a5648611/"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/">Ms. Tanu Mehta</Link>
                  </h5>
                  <p>
                    Legal Counsel,<br></br> Mediator and Conciliator
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="assets/img/team/Mr Ashok Barat.png" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/ashok-barat-35a62b4/"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/">Mr. Ashok Barat</Link>
                  </h5>
                  <p>
                    Former MD & CEO,<br></br> Forbes & Company Ltd 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="assets/img/team/Mr Raman Aggarwal.png" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/ramanaggarwalnbfc/"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/">Mr. Raman Aggarwal</Link>
                  </h5>
                  <p>
                    Director – FIDC, ST Consultant <br></br>World Bank
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="assets/img/team/Ankit Sahni.png" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/ankitsahni/"
                        target="_blank"
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/">Mr. Ankit Sahni</Link>
                  </h5>
                  <p>
                    {" "}
                    Principal,<br></br> Ajay Sahni Associates LLP
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <h5 className="text-center m-4">Former Advisor (In Memoriam)</h5>
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="assets/img/team/Ms Rajani Iyer.png" alt="img" />
                  {/* <ul className="team-social-inner">
                    <li>
                      <a href="#">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className="details">
                  <h5>
                    <Link to="/">Ms. Rajani Iyer</Link>
                  </h5>
                  <p> Senior Counsel and Mediator</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================== team area end ======================*/}
    </>
  );
};

export default TeamAreaOne;
