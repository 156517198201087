import React from "react";
import Marquee from "react-fast-marquee";

const BrandAreaOne = () => {
  return (
    <div className="about-area   pd-top-60 pd-bottom-60">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="section-title text-center">
            <h6 className="sub-title">Know Us</h6>
            <h2 className="title">
              <span>Alliances</span> and <span>Incubation</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="client-slider">
          <Marquee gradient={false}>
            <div className="thumb">
              <img src="assets/img/client/94-3-radio-one.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="assets/img/client/CAM.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="assets/img/client/DST.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="assets/img/client/startup-india.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="assets/img/client/business Today.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="assets/img/client/chirie-blair.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="assets/img/client/MSS.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="assets/img/client/NSR.png" alt="img" />
            </div>
            <div className="thumb">
              <img src="assets/img/client/SINE.png" alt="img" />
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default BrandAreaOne;
