import React from "react";

import AboutAreaThree from "../components/AboutAreaThree";
import BrandAreaOne from "../components/BrandAreaOne";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import Founders from "../components/Founders";
import FoundersStory from "../components/FoundersStory";
import NavBar from "../components/NavBar";

const About = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"About Us"} />

      {/* About Area One */}
      <AboutAreaThree />
      <FoundersStory />
      <Founders />
      <BrandAreaOne />
      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default About;
