import React from "react";

import BrandAreaOne from "../components/BrandAreaOne";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import ServiceAreaTwo from "../components/ServiceAreaTwo";

const WhatWeDo = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"What We Do?"} />

      {/* About Area One */}
      <ServiceAreaTwo />

      <BrandAreaOne />
      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default WhatWeDo;
